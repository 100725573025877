import React from "react";
import { AiOutlineMail, AiOutlinePhone, AiOutlineLinkedin } from "react-icons/ai";

function Profile() {
  return (
    <section className="third-section w-screen overflow-hidden flex justify-center" id="contact">
      <div className="pb-5 md:pb-10 px-3 md:px-10 lg:px-0 lg:w-5/6 pt-20 md:pt-5">
        <div className="grid md:grid-cols-3">
          <div className="flex items-center justify-center bg-blue-900 p-4 lg:p-8 h-96 md:h-64 lg:h-full">
            <img
              src="./profile.png"
              className="rounded-full border-4 border-white max-h-full max-w-full"
              alt="owner-img"
            />
          </div>
          <div className="flex flex-col px-4 md:px-10 md:py-2 justify-around h-36 md:col-span-2 md:h-full md:border border-b-0 md:border-l-0">
            <div className="">
              <h1 className="text text-3xl font-black text-blue-900">Didier Frangeard</h1>
              <span className="text font-bold">Owner</span>
            </div>
            <div className="lg:flex flex-col space-y-4 hidden py-2">
              <p className="text font-light italic lg:text-lg">
                Alttas is the result of 35 years working as R&D engineer and business developer. After creating my first
                company in 2011 for the development of products range based on a disruptive innovation, I created this
                new one in order to help industrial companies in their projects for growing.
              </p>
              <p className="text font-light italic lg:text-lg">
                Alttas is a small company of experts dedicated to innovation in technical development of products and in
                funding research. Base on our know-how and international network, our Core Business is the
                transformation of industrial and technology companies in order to create value.
              </p>
              <p className="text font-light italic lg:text-lg">
                Your project is unique, you are looking for growing locally or internationally, do not hesitate to
                contact us we will study your project in a win-to-win approach.
              </p>
            </div>
            <div className="flex justify-between lg:justify-around">
              <div className="flex flex-col justify-center space-y-2">
                <a className="flex gap-2" href="mailto:didier.frangeard@alttas.com" target="_blank" rel="noreferrer">
                  <AiOutlineMail className="h-6 w-6 text-blue-900" />
                  <span className="text">didier.frangeard@alttas.com</span>
                </a>
                <div className="flex gap-2">
                  <AiOutlinePhone className="h-6 w-6 text-blue-900" />
                  <span className="text">+34 673 001 509</span>
                </div>
              </div>
              <a
                href="https://www.linkedin.com/in/didier-frangeard-3a2bb933/"
                target="_blank"
                rel="noreferrer"
                className="flex justify-center items-center">
                <AiOutlineLinkedin className="h-10 w-10 text-blue-900" />
              </a>
            </div>
          </div>
          <div className="flex space-y-2 flex-col px-4 md:px-5 py-2 md:col-span-3 md:border border-t-0 lg:hidden">
            <p className="text font-light italic">
              Alttas is the result of 35 years working as R&D engineer and business developer. After creating my first
              company in 2011 for the development of products range based on a disruptive innovation, I created this new
              one in order to help industrial companies in their projects for growing.
            </p>
            <p className="text font-light italic">
              Alttas is a small company of experts dedicated to innovation in technical development of products and in
              funding research. Base on our know-how and international network, our Core Business is the transformation
              of industrial and technology companies in order to create value.
            </p>
            <p className="text font-light italic">
              Your project is unique, you are looking for growing locally or internationally, do not hesitate to contact
              us. We will study your project in a win-to-win approach.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Profile;
