import React, { useState, useEffect } from "react";
import { Business, Landing, Profile } from "./pages";
import { NavBar } from "./components";

function App() {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const secondSection = document.querySelector(".second-section");
      if (secondSection) {
        const distance = secondSection.getBoundingClientRect().top;
        setIsNavbarFixed(distance <= 0);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="overflow-hidden">
      <NavBar isFixed={isNavbarFixed} />
      <Landing />
      <Business />
      <Profile />
    </div>
  );
}

export default App;
