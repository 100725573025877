import React from "react";
import "./Landing.css";

function Landing() {
  return (
    <div className="min-h-screen w-screen overflow-hidden" id="home">
      <div className="landing-page w-full bg-green-50 mt-20 h-screen">
        <div className="pb-20 h-full">
          <div className="h-full flex flex-col justify-center md:pl-28 text-center md:text-left">
            <div className="relative">
              <h1 className="text-white text-4xl lg:text-6xl font-black pb-5" style={{ fontFamily: "Rem" }}>
                ALTTAS International
              </h1>
              <div className="test flex flex-col gap-5 md:max-w-[530px]">
                <p className="text-white text-xl md:text-2xl italic" style={{ fontFamily: "Rem" }}>
                  The result of more of thirty years of experience in engineering, products and business development.
                </p>
                <p className="text-white text-xl md:text-2xl italic" style={{ fontFamily: "Rem" }}>
                  We can propose you services in Mechatronics technical expertise and in Business development.
                </p>
              </div>
              <div className="absolute -bottom-24 md:-bottom-28 h-24 md:right-0 md:pr-28 text-center w-full md:w-fit pt-4 lg:pt-0">
                <h1
                  className="text-ble-900 text-white text-xl md:text-3xl lg:text-4xl font-bold"
                  style={{ fontFamily: "Rem" }}>
                  Innovation Experts
                </h1>
                <h1
                  className="text-bue-900 text-white text-xl md:text-3xl lg:text-4xl font-bold"
                  style={{ fontFamily: "Rem" }}>
                  in Technical & Financiation
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
