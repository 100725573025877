import React from "react";

function NavBar({ isFixed }: { isFixed: boolean }) {
  return (
    <nav className={`bg-white fixed h-20 w-full ${isFixed ? "border-b-2" : ""} inline-flex items-center justify-center z-10`}>
      <div className="w-5/6 flex items-center justify-between">
        <a className="flex items-center justify-center gap-4" href="#home">
          <img src="./logo192.png" className="h-12 w-12 md:h-14 md:w-14" alt="alttas-logo" />
          <span className="font-black text-2xl text-blue-900 hidden md:block" style={{ fontFamily: "Rem" }}>
            ALTTAS International
          </span>
        </a>
        <div className="flex flex-row lg:space-x-20 space-x-5 text-blue-900">
          <a className="font-bold text-lg" style={{ fontFamily: "Rem" }} href="#core-business">
            Core Business
          </a>
          <a className="font-bold text-lg" style={{ fontFamily: "Rem" }} href="#contact">
            Contact
          </a>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
