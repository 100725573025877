import React from "react";

function Business() {
  return (
    <div className="second-section min-h-screen w-screen overflow-hidden flex justify-center pb-5" id="core-business">
      <div className="px-3 md:px-10 lg:px-0 lg:w-5/6 pt-20 md:pt-5">
        <div className="grid md:grid-cols-3 md:grid-flow-row gap-4">
          <div className="md:h-96 md:col-span-2 flex flex-col justify-evenly">
            <div className="flex items-center justify-center text-center">
              <h1 className="text text-blue-900 font-bold text-xl">
                Expert Guidance in Mechatronics, Machine Learning & Industry 4.0
              </h1>
            </div>
            <div className="flex items-center justify-center text-center">
              <p className="text font-light italic text-base lg:text-lg pt-3 md:pt-0 lg:px-20">
                Experience the power of guidance firsthand with our dedicated consultant. When it comes to mechatronics,
                machine learning and Industry 4.0, we are your trusted source of information and experience. We're here
                to give you the tools you need to navigate the complexities of modern technology.
              </p>
            </div>
          </div>
          <div className="md:h-96 flex items-center">
            <img
              className="w-full"
              src="https://img.freepik.com/free-vector/job-interview-conversation_74855-7566.jpg"
              alt="conv-img"
            />
          </div>
          <div className="h-20 md:col-span-3 flex items-center justify-center text-center">
            <h1 className="text text-blue-900 font-bold text-xl">
              Business Development Services for International Grow-Up & Expansion of Start-up and Small Companies
            </h1>
          </div>
          <div className="md:h-96 md:col-span-2 grid grid-rows-2 md:grid-cols-2 bg--100 rounded-lg shadow">
            <div className="md:h-96 flex items-center justify-center text-center px-4">
              <p className="text font-light italic text-base lg:text-lg">
                Our expertise covers intellectual property, including patent support and drafting, as well as
                comprehensive cybersecurity solutions. We're here to protect your innovations and help your business on
                its journey to success.
              </p>
            </div>
            <div className="md:h-96 flex items-center">
              <img
                className="w-full"
                src="https://img.freepik.com/free-vector/colleagues-working-together-project_74855-6308.jpg"
                alt="work-img"
              />
            </div>
          </div>
          <div className="md:row-span-2 grid md:grid-rows-2 bg--200 rounded-lg shadow">
            <div className="md:h-96 flex items-center justify-center text-center px-4">
              <p className="text font-light italic text-base lg:text-lg">
                Improve your business performance with our comprehensive business plan development, marketing strategy
                and commercial strategy strategy and commercial strategy enhancement. Our expert guidance will enable
                you to chart a course towards sustainable growth and greater market impact.
              </p>
            </div>
            <div className="md:h-96 flex items-center">
              <img
                className="w-full"
                src="https://img.freepik.com/free-vector/team-leader-teamwork-concept_74855-6671.jpg"
                alt="leader-img"
              />
            </div>
          </div>
          <div className="md:h-96 md:col-span-2 grid md:grid-cols-2 reve bg--300 rounded-lg shadow">
            <div className="md:h-96 flex items-center order-last md:order-1">
              <img
                className="w-full"
                src="https://img.freepik.com/free-vector/coach-speaking-before-audience-mentor-presenting-charts-reports-employees-meeting-business-training-seminar-conference-vector-illustration-presentation-lecture-education_74855-8294.jpg"
                alt="speech-img"
              />
            </div>
            <div className="md:h-96 flex items-center justify-center text-center px-4">
              <p className="text font-light italic text-base lg:text-lg">
                Our insights provide you with a clear financial roadmap, unlocking opportunities and guiding your
                business towards prosperous collaborations and sustainable growth. With a meticulous focus on financial
                assessment, we research potential investors and strategic partners in depth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Business;
